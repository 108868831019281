
.wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  gap: 20px
}

.inputs {
  margin-top: 40px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 10px !important;
  }
}
