.footer {
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.files {
  display: flex;
  gap: 8px;

  & > .preview {
    width: 10%;
    position: relative;
    box-shadow: var(--shadow);

    & > .del {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      fill: #FFFFFF;
      background-color: rgba(0,0,0,0.4);

      &:hover {
        fill: #E3405E;
      }
    }

    & > .loading {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0,0,0,0.4);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.input {
  display: flex;
  align-items: center;
  gap: 8px;

  & > .textAria {
    width: 100%;
    padding: 8px;
    resize: none;
  }
}
