
.msg {
  display: flex;
  position: relative;

  &.right {
    justify-content: end;
    & > .content > .time {
      padding-left: 2px;
      float: right;
    }
  }

  &.left {
    justify-content: start;

    & > .content > .time {
      padding-right: 2px;
    }
  }

  & > .content {
    word-break: break-all;
    max-width: 80%;
    white-space: pre-wrap;
    line-height: 20px;
    font-size: 14px;
    margin: 2px 8px;
    padding: 8px 12px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);

    & > .name {
      opacity: 0.5;
    }

    &.day {
      margin-top: 24px;

      & > .date {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;

        & > div {
          padding: 2px 8px;
          font-size: 12px;
          font-weight: 600;
          border-radius: 16px;
        }
      }
    }

    & > .time {
      height: 20px;
      display: flex;
      align-items: end;
      gap: 2px;
      font-size: 12px;
      line-height: 12px;
      shape-outside:inset(calc(100% - 12px) 0 0);

      & > .read {
        font-size: 14px;
      }
    }
  }
}

.files {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  & > .preview {
    padding: 4px;
    max-height: 150px;
    overflow: hidden;
    box-shadow: var(--shadow);

    & img {
      height: 150px;
      width: 150px;
    }

    & iframe, & video {
      max-height: 150px;
    }
  }
}
