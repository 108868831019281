
.wrapper {
  display: flex;
  gap: 16px;

  & > * {
    width: 50%;
  }
}

.buttons {
  & > * {
    width: 100%;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & > * {
    width: 100%;
    margin-bottom: 15px !important;
  }
}
