.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: var(--shadow);

  & > .box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.menu {
  display: flex;
  gap: 8px;
}

.body {
  height: 100%;
  max-height: 100%;

  overflow: auto;
}
