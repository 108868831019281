.wrapper {
  display: flex;
  gap: 16px;

  & > * {
    width: 50%;
  }
}

@media (max-width: 720px) {

  .controller {
    padding-bottom: 30%;
  }

  .wrapper {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
}
