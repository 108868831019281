
.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px
}

.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
