:root {
  --shadow: 0 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & > * {
    min-height: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

textarea {
  font-size: inherit;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

img {
  width: 100%;
}

.controller {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 0;
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > * {
    max-width: calc(25% - 20px);
    width: 100%;
  }
}

@media (max-width: 750px) {

  .filter {
    flex-direction: column;
    & > * {
      max-width: 100%;
    }
  }
}

@media (max-width: 1200px) and (min-width: 750px) {

  .filter {
    & > * {
      max-width: calc(50% - 20px);
    }
  }
}
