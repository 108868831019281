
.wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
}

.inputs {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  & > * {
    margin-right: 20px;
  }
}
